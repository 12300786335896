body {
    margin: 0;
    padding: 0;
    font-family:"Rubik" !important;
    display: grid;
    grid-template-rows:65px 1fr 65px ;
    height: 100%;
    background-color: #f9f9f9;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
.d-flex{
    display: flex;
}

.container{
    max-width:1400px;
    margin: 0 auto;
    height: 100%;
    width: 100%;
}
.active a{
    color: #E50914;
}
.main-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-top: 50px;
}


/* ========== Header =========*/
header{
    width: 100%;
    border-bottom: 1px solid #fff;
}
.overlay{
height: 100%;
width: 100%;
position: absolute;
top: 0;
bottom: 0;
left: 0;
background-color: #000;
z-index: 9;
opacity: 30%;
}
.nav-item a{
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.banner{
    background: url(../../Assets/back.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh;
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
}
.banner-content{
    width: 50%;
    max-width: 700px;
    text-align: start;
    margin-bottom: 30px;
    z-index: 99;
}
.banner-content h3{
font-size: 30px;
font-weight: 700;
color: #fff;
margin-bottom: 15px;
}
.banner-content p{
    font-size: 15px;
    font-weight: 700;
    padding-right: 15px;
    color: #fff;
    margin-bottom: 30px;
    }
header .container{
    height: 100%;
    background-color: #fff;

}
.shadow{
    -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.top-header{
    height: 90px;
    background-color: #fff;
    box-shadow: 0px 0px 8px rgba(0,0,0,.6);
    position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
}
.top-header-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.logo h5{
    font-family: 'Rubik';
    color: #E50914;
    font-size: 25px;
    margin: 0 15px;
}
.header-logo{
    flex-grow: 2;
    display: flex;
}
.header-nav{
    flex-grow: 4;
    display: flex;
    align-items: center;
    justify-content: end;
}
.nav-list{
    list-style: none;
    display: flex;
    margin: 0px 20px;
}
.nav-link{
    text-decoration: none;
    color: #E50914;
    margin: 0px  5px;
}
.get-start-link{
    padding: 10px 24px;
    background-color: #E50914;
    color: #fff;
}
    input.search-input{
        margin-right: 5px;
        height: 35px;
        border-radius: 3%;
        border-color: #dc3545;
        border-style: solid;
        box-shadow: 3px 3px 3px 2px rgba(44, 44, 44, 0.2);
    }

    input.search-input:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
      }